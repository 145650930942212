import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';


const baseUrl = environment.apiUrl;
const url1 = baseUrl + '/register-business';
const url2 = baseUrl + '/get-summary';
const url3 = baseUrl + '/get-businesses';
const url4 = baseUrl + '/get-business/';
const url5 = baseUrl + '/update-business';
const url6 = baseUrl + '/export-businesses';
const url7 = baseUrl + '/filter-values';
const url8 = baseUrl + '/login';
const url9 = baseUrl + '/get-users';
const url10 = baseUrl + '/add-user';
const url11 = baseUrl + '/update-user';
const url12 = baseUrl + '/get-user/';
const url13 = baseUrl + '/export-users';
const url14 = baseUrl + '/get-user-password/';
const url15 = baseUrl + '/change-user-password';
const url16 = baseUrl + '/reset-password/';
const url17 = baseUrl + '/delete-business/';

@Injectable({
  providedIn: 'root'
})
export class ModuleService {
  private headers = new HttpHeaders({'Content-Type': 'application/json', 'X-Requested-Width': 'XMLHttpRequeest',
  'Access-Control-Allow-Origin': 'true'});

  constructor(private http: HttpClient) { }

  registerBusiness(data) {
    return this.http.post (url1 , data, {headers: this.headers});
  }

  getSummary() {
    return this.http.get (url2 , {headers: this.headers});
  }

  getBusinesses() {
    return this.http.get (url3 , {headers: this.headers});
  }

  getBusiness(id) {
    return this.http.get (url4 + id , {headers: this.headers});
  }

  updateBusiness(data) {
    return this.http.post (url5 , data, {headers: this.headers});
  }

  export() {
    window.open(url6);
  }

  exportUsers() {
    window.open(url13);
  }

  getFilterValues() {
    return this.http.get (url7 , {headers: this.headers});
  }

  login(data) {
    return this.http.post (url8 , data, {headers: this.headers});
  }

  getUsers() {
    return this.http.get (url9 , {headers: this.headers});
  }

  addUser(data) {
    return this.http.post (url10 , data, {headers: this.headers});
  }

  updateUser(data) {
    return this.http.post (url11 , data, {headers: this.headers});
  }

  getUser(id) {
    return this.http.get (url12 + id , {headers: this.headers});
  }

  resetPwd(id) {
    return this.http.get(url16 + id, {headers: this.headers});
  }

  getUserPassword(id) {
    return this.http.get (url14 + id , {headers: this.headers});
  }

  changePassword(data) {
    return this.http.post (url15 , data, {headers: this.headers});
  }

  deleteBusiness(id) {
    return this.http.delete(url17 + id, {headers: this.headers});
  }

  setCredentials(id, username, role) {
    localStorage.setItem('username', username);
    localStorage.setItem('id', id);
    localStorage.setItem('role', role);
  }

  get isAdmin() {
     const role = localStorage.getItem('role');
     if (role === 'Admin') {
        return true;
     }
     return false;
  }

  get isDataEntry() {
    const role = localStorage.getItem('role');
    if (role === 'Data entry') {
       return true;
    }
    return false;
 }

 get isRegistrar() {
  const role = localStorage.getItem('role');
  if (role === 'Registrar') {
     return true;
  }
  return false;
}

get isInspector() {
  const role = localStorage.getItem('role');
  if (role === 'Inspector') {
     return true;
  }
  return false;
}

  isAuth() {
   let isAuth;
   const auth = localStorage.getItem('username');
   if (auth === null) {
    isAuth = false;
   }
   if (auth !== null) {
     isAuth = true;
   }

   return isAuth;
  }

  removeCredentials() {
      localStorage.removeItem('username');
      localStorage.removeItem('id');
  }

  getUsername() {
    return localStorage.getItem('username');
  }

  getId() {
    return localStorage.getItem('id');
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';

// const baseUrl = 'http://127.0.0.1:8000/api';
const baseUrl = environment.apiUrl;
const url1 = baseUrl + '/send-message';
const url2 = baseUrl + '/get-messages';
const url3 = baseUrl + '/send-message-to-given';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  private headers = new HttpHeaders({'Content-Type': 'application/json'});

  constructor(private http: HttpClient) { }

  getSendMessage(data) {
    return this.http.post(url1, data, {headers: this.headers});
  }

  getSendMessageToGiven(data) {
    return this.http.post(url3, data, {headers: this.headers});
  }

  getMessages() {
    return this.http.get(url2, {headers: this.headers});
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';

// const baseUrl = 'http://127.0.0.1:8000/api';
const baseUrl = environment.apiUrl;
const url1 = baseUrl + '/add-inspection';
const url2 = baseUrl + '/get-inspection-summary/';
const url3 = baseUrl + '/get-inspections';
const url4 = baseUrl + '/get-specific-inspection/';
const url5 = baseUrl + '/get-inspection-summary';
const url6 = baseUrl + '/export-inspections';
const url7 = baseUrl + '/get-batch-values';

@Injectable({
  providedIn: 'root'
})
export class InspectionService {
  private headers = new HttpHeaders({'Content-Type': 'application/json'});

  constructor(private http: HttpClient) { }

  addInspection(data) {
    return this.http.post(url1 , data, {headers: this.headers});
  }

  getSummary(id) {
    return this.http.get(url2 + id, {headers: this.headers});
  }

  getSummaryGeneral() {
    return this.http.get(url5, {headers: this.headers});
  }

  getInspections() {
    return this.http.get(url3 , {headers: this.headers});
  }

  getInspection(id) {
    return this.http.get(url4 + id, {headers: this.headers});
  }

  getBatchValues(data) {
    return this.http.post(url7,  data, {headers: this.headers});
  }

  export() {
    window.open(url6);
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InspectionService } from '../inspection.service';

@Component({
  selector: 'app-specific-inspection',
  templateUrl: './specific-inspection.component.html',
  styleUrls: ['./specific-inspection.component.css']
})
export class SpecificInspectionComponent implements OnInit {
  id;
  inspection;
  stocks;

  constructor(
    private active: ActivatedRoute,
    private router: Router,
    private inspectionService: InspectionService
  ) {
    this.active.params.subscribe( params => this.id = params['id']);
  }

  ngOnInit() {
    this.inspectionService.getInspection(this.id).subscribe(
      (data: Data) => {
        this.inspection = data.inspection;
        this.stocks = data.stocks;
      }
    );
  }

}

export interface Data {
  inspection;
  stocks;
}


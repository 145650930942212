import { Component, OnInit } from '@angular/core';
import { DashboardService } from './dashboard.service';
import * as Highcharts from 'highcharts';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  cards = [];
  pie = [];
  data: Data;
  kgs;
  litres;
  regions;

  constructor(
    private dashboardService: DashboardService,
    private router: Router
    ) {

  }

  ngOnInit() {
    this.dashboardService.getDashStats().subscribe(
      (data: Data) => {
        this.data = data;
        this.litres = data.litres;
        this.kgs = data.kgs;
        this.regions = data.regions;

        console.log(data)
      }
    );
    // this.cards = this.dashboardService.cards();
    // this.pie = this.dashboardService.pie();
    // this.dashboardService.getDashStats().subscribe(
    //   (data: Data) => {
    //     console.log(data);
    //     this.data = data;
    //     this.bigChart = data.stock;
    //   }
    // );
  }

  toBusiness(category) {
    this.router.navigate(['/app/businesses', {category}]);
  }
  toValidity(status) {
    this.router.navigate(['/app/businesses', {status}]);
  }
  toPesticides() {
    this.router.navigate(['/app/pesticides']);
  }

}

export interface Data {
  importers;
  sellers;
  repackers;
  fumigators;
  formulators;
  stock;
  valid;
  expired;
  pesticides;
  wholesale_sellers;
  litres;
  kgs;
  regions;
}

import { Component, OnInit , ViewChild} from '@angular/core';
import { FormBuilder, FormArray } from '@angular/forms';
import { Validators, FormGroupDirective} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { StockService } from '../stock.service';

@Component({
  selector: 'app-add-stock',
  templateUrl: './add-stock.component.html',
  styleUrls: ['./add-stock.component.css']
})
export class AddStockComponent implements OnInit {
  @ViewChild(FormGroupDirective, {static: false}) formGroupDirective: FormGroupDirective;

  pesticideForm = this.fb.group({
    importer: ['', Validators.required],
    batchNumber:  ['', Validators.required],
    permitNumber: ['', Validators.required],
    pesticide:  ['', Validators.required],
    quantity:  ['', Validators.required],
    manu_date:  ['', Validators.required],
    unit:  ['', Validators.required],
    target_crop:  [''],
    exp_date:  ['', Validators.required],
    origin_country: ['', Validators.required],
    stock_remained: this.fb.array([])
  });
  edit = false;
  paramId;
  pesticides;
  importers;
  countries;
  previousStock = [];
  units = ['Litre', 'Kg'];

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private active: ActivatedRoute,
    private stockService: StockService,
    private loc: Location
  ) {
    this.active.params.subscribe( params => this.paramId = params['id']);

    if (this.paramId !== undefined) {
         this.edit = true;
    }
  }

  ngOnInit() {
    this.getSummary();
    // if (this.edit) {
    //  this.pesticideService.getPesticide(this.paramId).subscribe(
    //    (data: Data) => {
    //     this.pesticide = data;
    //     this.pesticideForm.get('trade_name').setValue(data.trade_name);
    //     this.pesticideForm.get('common_name').setValue(data.common_name);
    //     this.pesticideForm.get('reg_number').setValue(data.reg_number);
    //     this.pesticideForm.get('reg_status').setValue(data.reg_status);
    //     this.pesticideForm.get('reg_type').setValue(data.reg_type);
    //     this.pesticideForm.get('valid_from').setValue(data.valid_from);
    //     this.pesticideForm.get('valid_to').setValue(data.valid_to);
    //     this.pesticideForm.get('usage').setValue(data.usage);
    //     this.pesticideForm.get('registrant').setValue(data.registrant);
    //    }
    //  );
    // }
  }

  getSummary() {
    this.stockService.getSummary().subscribe(
      (data: Summary) => {
        this.pesticides = data.pesticides;
        this.importers = data.importers;
        this.countries = data.countries;
      }
    );
  }

  getPreviousStock() {
   const data =  {
     importerId: this.pesticideForm.get('importer').value,
     pesticideId: this.pesticideForm.get('pesticide').value
    };
   this.stockService.getPreviousStock(data).subscribe(
      (data: []) => {
      this.previousStock = data;
      console.log(data);
      this.previousStock.forEach(element => {
        this.prev_stock.push(
          this.fb.group({
            batch_number: [element['batch_number'], Validators.required],
            import: [element['quantity'], Validators.required],
            stock_remained: ['', Validators.required],
          })
        );
      });
      }
    );
  }

  get prev_stock() {
    return this.pesticideForm.get('stock_remained') as FormArray;
  }

  errorMessage() {
    return 'Field is required';
  }

  onSubmit() {
    this.stockService.addStock(this.pesticideForm.value).subscribe(
      (data) => {
        this.onOpenSnackBar('Stock added successfully');
        this.pesticideForm.reset();
        this.formGroupDirective.resetForm();
      }
    );
  }

  goBack() {
    this.loc.back();
  }

  onUpdate() {
    // const data = {
    //   data: this.pesticideForm.value,
    //   pesticideId: this.pesticide.id
    // };

    // this.pesticideService.updatePesticide(data).subscribe(
    //   (data) => {
    //     this.onOpenSnackBar('Pesticide successfully updated');
    //   }
    // );
  }

  onOpenSnackBar(message: string) {
    this.snackBar.open(message, '', { duration: 3000});
  }

}
export interface Summary {
  importers;
  pesticides;
  countries;
}

export interface Data {
  trade_name;
  common_name;
  reg_number;
  reg_type;
  reg_status;
  valid_from;
  valid_to;
  registrant;
  usage;
}

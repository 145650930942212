import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModuleService } from '../../business/module.service';

@Component({
  selector: 'app-specific-user',
  templateUrl: './specific-user.component.html',
  styleUrls: ['./specific-user.component.css']
})
export class SpecificUserComponent implements OnInit {
  id;
  user;

  constructor(
    private moduleService: ModuleService,
    private active: ActivatedRoute,
    private router: Router
  ) {
    this.active.params.subscribe( params => this.id = params['id']);
  }

  ngOnInit() {
    this.moduleService.getUser(this.id).subscribe(
      (data: Data) => {
        this.user = data;
      }
    );
  }

  onEdit(id) {
    this.router.navigate(['app/add-user', {id}]);
  }

}

export interface Data {
 adviser;
 business;
}

import { Component, OnInit, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
HC_exporting(Highcharts);

@Component({
  selector: 'app-widget-area',
  templateUrl: './area.component.html',
  styleUrls: ['./area.component.css']
})
export class AreaComponent implements OnInit {

  constructor() { }

  @Input() litres = [];
  @Input() kgs = [];
  @Input() regions = [];

  Highcharts = Highcharts;

  chartOptions: {};

  ngOnInit() {
    this.chartOptions = {
      chart: {
          type: 'column'
      },
      title: {
          text: 'AVAILABLE STOCK DISTRIBUTION IN REGIONS'
      },
      // subtitle: {
      //     text: 'demo graph'
      // },
      xAxis: {
          type: 'category',
          categories: this.regions,
          tickmarkPlacement: 'on',
          title: {
              // enabled: false
              text: 'Region'
          },
          crosshair: true
      },
      yAxis: {
          min: 10,
          title: {
              text: 'Stock'
          },
          labels: {
              formatter() {
                  return this.value;
              }
          }
      },
      legend: {
        enabled: true
      },
      // tooltip: {
      //     split: true,
      //     valueSuffix: ' measure'
      // },
      credits: {
      enabled: false
      },
      exporting: {
        enabled: true
      },
      // plotOptions: {
      //     area: {
      //         stacking: 'normal',
      //         lineColor: '#666666',
      //         lineWidth: 1,
      //         marker: {
      //             lineWidth: 1,
      //             lineColor: '#666666'
      //         }
      //     }
      // },
      series: [
        {
        name: 'litres',
        data: this.litres },
        {
          name: 'Kg',
          data: this.kgs }
      ]
  };
    HC_exporting(Highcharts);
    setTimeout(() => {
    window.dispatchEvent(
      new Event('resize')); }, 300);
}
}

import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormControl, FormBuilder } from '@angular/forms';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { ModuleService } from '../../business/module.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  passwordForm = this.fb.group({
    password: ['', Validators.required],
    new_password: ['', Validators.required],
    re_type: ['', Validators.required],
  });
  userId = this.moduleService.getId();
  password;
  match = true;

  constructor(
    private snackbar: MatSnackBar,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    private moduleService: ModuleService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.moduleService.getUserPassword(this.userId).subscribe(
      (data) => {
       this.password = data;
      }
    );
  }

  verify() {
    if (this.passwordForm.get('new_password').value !== this.passwordForm.get('re_type').value) {
      this.match = false;
      this.passwordForm.get('re_type').setErrors({notMatched: true});
    } else {
      this.match = true;
      this.passwordForm.get('re_type').setErrors(null);
    }
  }

  onSubmit() {
    const data = {
      userId: this.userId,
      password: this.passwordForm.get('new_password').value,
      prev_password: this.passwordForm.get('password').value
    };

    this.moduleService.changePassword(data).subscribe(
        (data) => {
          if (data === 'no match') {
            this.snackbar.open('Entered password does not match your previous password, contact with admin', '', {
              duration: 4000,
            });
          } else {
            this.snackbar.open('New password updated', '', {
              duration: 3000,
            });
            this.onNoClick();
          }
          } );
   }

  onNoClick() {
    this.dialogRef.close();
  }

  errorMessage() {
    return 'Field is required';
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Validators, FormGroupDirective } from '@angular/forms';
import { ModuleService } from 'src/app/modules/business/module.service';
import { Router } from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {
  authForm = this.fb.group({
    username: ['', Validators.required],
    password: ['', Validators.required]
  });

  constructor(private fb: FormBuilder,
              private moduleService: ModuleService,
              private route: Router,
              private snackBar: MatSnackBar) { }

  ngOnInit() {
  }

  onSubmit() {
    this.moduleService.login(this.authForm.value).subscribe(
      (data: string) => {
        console.log(data)
        if (data.hasOwnProperty('id')) {
         this.moduleService.setCredentials(data['id'], data['username'], data['role']);
         this.route.navigate(['/app']);
        } else {
          this.snackBar.open(data, '', {
            duration: 2000,
          });
        }
      }
    );
  }
  errorMessage() {
    return 'Field is required';
  }

}

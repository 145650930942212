import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {FormControl, Validators, FormBuilder, FormArray, FormGroup} from '@angular/forms';
import { Router } from '@angular/router';
import { PesticideService } from '../pesticide.service';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import {MatPaginator} from '@angular/material/paginator';
import { ModuleService } from '../../business/module.service';
import { ImportDialogComponent } from '../../shared/import-dialog/import-dialog.component';
// tslint:disable-next-line: import-blacklist
// import 'rxjs/Rx';
import { DeleteDataComponent } from './../../../shared/components/delete-data/delete-data.component';

@Component({
  selector: 'app-pesticides',
  templateUrl: './pesticides.component.html',
  styleUrls: ['./pesticides.component.css']
})
export class PesticidesComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  displayedColumns: string[] = ['position', 'trade', 'common', 'type', 'regNumber', 'usage', 'registrant', 'regStatus', 'actions'];
  displayedColumnsIsNotAuth: string[] = ['position', 'trade', 'common', 'type', 'regNumber', 'usage', 'registrant', 'actions'];
  dataSource;
  pesticides: Pesticide[];
  bulkPesticides: Pesticide[];
  uploading = false;
  clear = false;
  tradeName = new FormControl('');
  commonName = new FormControl('');
  usage = new FormControl('');
  type = new FormControl('');
  regStatus = new FormControl('');
  usages;
  commonNames;
  isAuth;
  statuses = [
    {value: 'valid', viewValue: 'Valid'},
    {value: 'expired', viewValue: 'Expired'},
  ];
  types = [
    'Acaricides(AC)',
    'Avicides(AV)',
    'Biological Control Agent (BCA)',
    'Fungicides(FU)',
    'Growth Regulators(GR)',
    'Herbicides(HE)',
    'Molluscides(MO)',
    'Nematides(NE)',
    'Restricted(RE)',
    'Rodenticides(RO)',
    'Insecticides(IN)'
  ];

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    private route: Router,
    private pesticidesService: PesticideService,
    public moduleService: ModuleService,
    private http: HttpClient) {
      this.isAuth = moduleService.isAuth();
    }

  ngOnInit() {
    this.pesticidesService.getPesticides().subscribe(
      (data: Pesticide[]) => {
       this.pesticides = data;
       this.bulkPesticides = data;
       this.dataSource = new MatTableDataSource(this.pesticides);
       this.dataSource.sort = this.sort;
       this.dataSource.paginator = this.paginator;
      }
    );

    this.getSummary();
  }
  getSummary() {
    this.pesticidesService.getSummary().subscribe(
      (data: Data) => {
        this.usages = data.usages;
        this.commonNames = data.common_names;
      }
    );
  }
  showAdd() {
    if (this.moduleService.isRegistrar) {
      return false;
    }
    if (this.moduleService.isInspector) {
      return false;
    }
    return true;
  }

  onAddPesticide() {
    this.route.navigate(['app/add-pesticide']);
  }
  toDetails(id) {
    // console.log(id)
    this.route.navigate(['app/specific-pesticide', {id}]);
  }
  toDetailsNotAuth(id) {
    console.log(id)
    this.route.navigate(['public/specific-pesticide', {id}]);
  }
  onEdit(id) {
    this.route.navigate(['app/add-pesticide', {id}]);
  }

  onDelete(id) {
    const dialogRef = this.dialog.open(DeleteDataComponent, {
      width: '500px',
      height: '300px',
      data: {id: id, type: 'pesticide'}
    });

    dialogRef.afterClosed().subscribe(data =>{
      this.ngOnInit();
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  clearFilter() {
    this.tradeName.setValue('');
    this.commonName.setValue('');
    this.usage.setValue('');
    this.type.setValue('');
    this.regStatus.setValue('');
    this.pesticides = this.bulkPesticides;
    this.dataSource = new MatTableDataSource(this.pesticides);
    this.clear = false;
  }

  onExport() {
   this.pesticidesService.export();
  }
  downloadFile(data) {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  onImport() {
    const dialogRef = this.dialog.open(ImportDialogComponent, {
      width: '500px',
      height: '250px',
      data: {type: 'pesticides'}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.pesticidesService.getPesticides().subscribe(
        (data: Pesticide[]) => {
         this.pesticides = data;
         this.dataSource = new MatTableDataSource(this.pesticides);
         this.dataSource.sort = this.sort;
         this.dataSource.paginator = this.paginator;
        }
      );
    });
   }

  applyGroupFilter() {
    let value: Pesticide[] = [];
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.bulkPesticides.length; i++) {

      // tslint:disable-next-line: max-line-length
      if (this.tradeName.value !== '' && this.commonName.value !== '' && this.usage.value !== '' && this.regStatus.value !== '' && this.type.value !== '' ) {
       if (this.bulkPesticides[i].trade_name === this.tradeName.value && this.bulkPesticides[i].common_name === this.commonName.value &&
           this.bulkPesticides[i].usage === this.usage.value && this.bulkPesticides[i].reg_status === this.regStatus.value
           && this.bulkPesticides[i].pesticide_type === this.type.value) {
         value.push(this.bulkPesticides[i]); }
        } else {
          if (this.tradeName.value !== '' && this.commonName.value !== '' && this.usage.value !== '' && this.type.value !== '') {
            if (this.bulkPesticides[i].trade_name === this.tradeName.value &&
              this.bulkPesticides[i].common_name === this.commonName.value &&
              this.bulkPesticides[i].usage === this.usage.value && this.bulkPesticides[i].pesticide_type === this.type.value) {
                value.push(this.bulkPesticides[i]); }
            } else {
              if (this.tradeName.value !== '' && this.commonName.value !== '' && this.type.value !== '') {
                if (this.bulkPesticides[i].trade_name === this.tradeName.value &&
                  this.bulkPesticides[i].common_name === this.commonName.value
                  && this.bulkPesticides[i].pesticide_type === this.type.value) {
                    value.push(this.bulkPesticides[i]); }
                } else {
                  if (this.tradeName.value !== '' ) {
                    if (this.bulkPesticides[i].trade_name === this.tradeName.value) {
                      value.push(this.bulkPesticides[i]); }
                    } else {
                      if (this.commonName.value !== '') {
                        if (this.bulkPesticides[i].common_name === this.commonName.value) {
                          value.push(this.bulkPesticides[i]); }
                        } else {
                          if (this.usage.value !== '') {
                            if (this.bulkPesticides[i].usage === this.usage.value) {
                              value.push(this.bulkPesticides[i]); }
                            } else {
                              if (this.regStatus.value !== '') {
                                if (this.bulkPesticides[i].reg_status.toLowerCase() === this.regStatus.value.toLowerCase()) {
                                  value.push(this.bulkPesticides[i]);
                                }
                                } else {
                                  if (this.type.value !== '') {
                                  if (this.bulkPesticides[i].pesticide_type === this.type.value) {
                                    value.push(this.bulkPesticides[i]); }
                                  }
                                }
                            }    }
                    }
                }
            }
        }
    }
    this.pesticides = value;
    this.dataSource = new MatTableDataSource(this.pesticides);
    this.clear = true;
  }
}

export interface Pesticide {
   trade_name;
   common_name;
   reg_number;
   registrant;
   usage;
   reg_type;
   reg_status;
   valid_from;
   valid_to;
   pesticide_type;
}

export interface Data {
  usages;
  common_names;
  }

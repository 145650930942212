import { Component, OnInit, ViewChild} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Validators, FormGroupDirective } from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ModuleService } from '../../business/module.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent  implements OnInit {
  @ViewChild(FormGroupDirective, {static: false}) formGroupDirective: FormGroupDirective;
  userForm = this.fb.group({
    firstname: ['', Validators.required],
    middlename: [''],
    lastname: ['', Validators.required],
    role: ['', Validators.required],
    email: ['', Validators.required],
    phone: ['', Validators.required],
    username: [''],
    password: [''],
  });
  edit = false;
  paramId;
  user;
  roles = [
    'Registrar',
    'Admin',
    'Data entry',
    'Inspector'
  ];
  profile = false;
  type;

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private active: ActivatedRoute,
    public moduleService: ModuleService,
    private route: Router) {
     this.active.params.subscribe( params => {
       this.paramId = params['id'];
       this.type = params['type'];
      });

     if (this.paramId !== undefined) {
          this.edit = true;
     }

     if (this.type !== undefined) {
      this.profile = true;
 }
    }

  ngOnInit() {
      if (this.edit) {
      this.moduleService.getUser(this.paramId).subscribe(
        (data: Data) => {
         this.user = data;
         this.userForm.controls['firstname'].setValue(data.firstname);
         this.userForm.controls['middlename'].setValue(data.middlename);
         this.userForm.controls['lastname'].setValue(data.lastname);
         this.userForm.controls['role'].setValue(data.role);
         this.userForm.controls['phone'].setValue(data.phone);
         this.userForm.controls['password'].setValue(data.password);
         this.userForm.controls['username'].setValue(data.username);
         this.userForm.controls['email'].setValue(data.email);
        }
      );
    }
  }

  errorMessage() {
    return 'Field is required';
  }

  onSubmit() {
    this.moduleService.addUser(this.userForm.value).subscribe(
      (data) => {
        this.onOpenSnackBar('User successfully registered');
        this.userForm.reset();
        this.formGroupDirective.resetForm();
      }
    );
  }

  resetPwd() {
    console.log('here');
    this.moduleService.resetPwd(this.paramId).subscribe(
      // tslint:disable-next-line: no-shadowed-variable
      (data) => {
        this.onOpenSnackBar('Password reseted successfully');
      }
    );
  }

  onUpdate() {
    const data = {
     userId: this.user.id,
     data: this.userForm.value
    };
    this.moduleService.updateUser(data).subscribe(
      // tslint:disable-next-line: no-shadowed-variable
      (data) => {
        this.onOpenSnackBar('User successfully updated');
      }
    );
  }

  onOpenSnackBar(message: string) {
    this.snackBar.open(message, '', { duration: 3000});
  }

}

export interface Data {
  firstname;
  middlename;
  lastname;
  phone;
  email;
  role;
  password;
  username;
}


import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {FormControl, Validators, FormBuilder, FormArray, FormGroup} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { StockService } from '../stock.service';
import {MatPaginator} from '@angular/material/paginator';
import { ModuleService } from '../../business/module.service';

@Component({
  selector: 'app-specific-stock',
  templateUrl: './specific-stock.component.html',
  styleUrls: ['./specific-stock.component.css']
})
export class SpecificStockComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  displayedColumns: string[] = ['position', 'pesticide', 'quality', 'location', 'validity', 'remained'];
  dataSource;
  stocks: Stock[];
  location = new FormControl('');
  name = new FormControl('');
  remain = new FormControl('');
  locations;
  bulkStock: Stock[];
  clear = false;
  pesticide;

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    private route: Router,
    private stockService: StockService,
    private active: ActivatedRoute,
    private moduleService: ModuleService) {
      this.active.paramMap.subscribe(params => this.pesticide = params.get('pesticide'));
    }

  ngOnInit() {
    const data = {
      pesticide: this.pesticide
    };
    this.stockService.getSpecificStock(data).subscribe(
      (data: Stock[]) => {
       this.stocks = data;
       this.bulkStock = data;
       this.dataSource = new MatTableDataSource(this.stocks);
       this.dataSource.sort = this.sort;
       this.dataSource.paginator = this.paginator;
      }
    );

    this.moduleService.getFilterValues().subscribe(
      (data: Data) => {
        this.locations = data.regions;
      }
    );
  }

  onExport() {
    this.stockService.export();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  clearFilter() {
    this.stocks = this.bulkStock;
    this.dataSource = new MatTableDataSource(this.stocks);
    this.clear = false;
    this.name.reset();
    this.location.reset();
  }

  groupFilter() {
    let value: Stock[] = [];
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.bulkStock.length; i++) {

      if (this.name.value !== '' && this.location.value !== '') {
       if (this.bulkStock[i].name.toLowerCase()  === this.name.value.toLowerCase()  &&
       this.bulkStock[i].regionId.toLowerCase()  === this.location.value.toLowerCase() ) {
         value.push(this.bulkStock[i]); }
        } else {
          if (this.name.value !== '' ) {
            if (this.bulkStock[i].name.toLowerCase()  === this.name.value.toLowerCase()  ) {
              value.push(this.bulkStock[i]); }
            } else {
              if (this.location.value !== '') {
                if (this.bulkStock[i].regionId.toLowerCase() === this.location.value.toLowerCase() ) {
                  value.push(this.bulkStock[i]); }
                }
                }
            }
        }
    this.stocks = value;
    this.dataSource = new MatTableDataSource(this.stocks);
    this.clear = true;
  }
  // onAddBussiness() {
  //   this.route.navigate(['/add-business']);
  // }
  // toDetails(id) {
  //   this.route.navigate(['/specific-business', {id}]);
  // }
  // onEdit(id) {
  //   this.route.navigate(['/add-business', {id}]);
  // }
}

export interface Stock {
   name;
   regNumber;
   category;
   regStatus;
   location;
   regionId;
}

export interface Data {
  regions;
}


import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {

  constructor(
    private route: Router
  ) { }

  ngOnInit() {
  }

  registerBusiness() {
      this.route.navigate(['/public/add-business']);
  }

  authorizedPesticides() {
    this.route.navigate(['/public/pesticides']);
  }

  authorizedBusinesses() {
    this.route.navigate(['/public/businesses']);
  }

}

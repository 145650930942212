import { Component, OnInit, ElementRef, ViewChild, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { Validators, FormGroupDirective } from '@angular/forms';
import { MessageService } from '../message.service';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {FormControl} from '@angular/forms';
import { ModuleService } from '../../business/module.service';
import { MatSnackBar } from '@angular/material';

export interface DialogData {
  businesses;
}
@Component({
  selector: 'app-add-message',
  templateUrl: './add-message.component.html',
  styleUrls: ['./add-message.component.css']
})
export class AddMessageComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  businesses: Business[];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fruitCtrl = new FormControl(['', Validators.required]);
  filteredFruits: Observable<string[]>;
  fruits: string[] = [];
  regions;
  statuses = ['all', 'valid', 'expired'];
  allFruits: string[] = ['All stakeholders', 'formulators', 'fumigators', 're-packers', 'importers', 'sellers'];
  messageForm = this.fb.group({
    bussinesses: [''],
    status: [''],
    location: [''],
    subject: [''],
    message: ['', Validators.required],
    sender : ['']
  });

  @ViewChild('fruitInput', {static: false}) fruitInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;

  constructor(
    public dialogRef: MatDialogRef<AddMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private messageService: MessageService,
    private businessService: ModuleService,
    private snackBar: MatSnackBar,
    private moduleService: ModuleService,
   ) {
    this.filteredFruits = this.fruitCtrl.valueChanges.pipe(
        startWith(null),
        map((fruit: string | null) => fruit ? this._filter(fruit) : this.allFruits.slice()));
    this.messageForm.get('sender').setValue(this.moduleService.getId());
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.fruits.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.fruitCtrl.setValue(null);
  }

  remove(fruit: string): void {
    const index = this.fruits.indexOf(fruit);

    if (index >= 0) {
      this.fruits.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.fruits.push(event.option.viewValue);
    this.fruitInput.nativeElement.value = '';
    this.fruitCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allFruits.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);
  }

  ngOnInit() {
    this.businessService.getBusinesses().subscribe(
      (data: Business[]) => {
        this.businesses = data;
      }
    );

    this.moduleService.getFilterValues().subscribe(
      (data: Data) => {
        this.regions = data.regions;
      }
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  errorMessage() {
    return 'Field is required';
  }

  onSubmit() {
  if (this.data === null) {
    this.messageForm.get('bussinesses').setValue(this.fruits);
    if (this.messageForm.valid) {
      this.messageService.getSendMessage(this.messageForm.value).subscribe(
        (data) => {
          this.onOpenSnackBar('Message sent successfully');
          this.onNoClick();
        }
      );
    }
  } else {
    const data = {
      bussinesses: this.data.businesses,
      subject: this.messageForm.get('subject').value,
      message: this.messageForm.get('message').value,
      sender: this.messageForm.get('sender').value
    };
    if (this.messageForm.valid) {
      this.messageService.getSendMessageToGiven(data).subscribe(
        (data) => {
          this.onOpenSnackBar('Message sent successfully');
          this.onNoClick();
        }
      );
    }
  }

  }

  onOpenSnackBar(message: string) {
    this.snackBar.open(message, '', {duration: 4000});
  }
}

export interface Business {
  name;
  regNumber;
  regionId;
  categoryId;
  regStatusId;
  location;
  email;
  phone;
}

export class Data {
  regions;
}

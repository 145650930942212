import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';

// const baseUrl = 'http://127.0.0.1:8000/api';
const baseUrl = environment.apiUrl;
const url1 = baseUrl + '/register-pesticide';
const url2 = baseUrl + '/get-pesticides';
const url3 = baseUrl + '/get-pesticide-summary';
const url4 = baseUrl + '/get-pesticide/';
const url5 = baseUrl + '/update-pesticide';
const url6 = baseUrl + '/delete-pesticide/';
const url7 = baseUrl + '/export-pesticides';

@Injectable({
  providedIn: 'root'
})
export class PesticideService {
  private headers = new HttpHeaders({'Content-Type': 'application/json'});

  constructor(private http: HttpClient) { }

  registerPesticide(data) {
    return this.http.post (url1 , data, {headers: this.headers});
  }

  getPesticides() {
    return this.http.get(url2, {headers: this.headers});
  }

  getSummary() {
    return this.http.get(url3, {headers: this.headers});
  }

  getPesticide(id) {
    return this.http.get(url4 + id, {headers: this.headers});
  }

  updatePesticide(data) {
    return this.http.post (url5 , data, {headers: this.headers});
  }

  deletePesticide(id) {
    return this.http.delete(url6 + id, {headers: this.headers});
  }

  export() {
    window.open(url7);
  }
}


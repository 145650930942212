import { Component, OnInit, ViewChild} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Validators, FormGroupDirective } from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Data } from '../specifict-business/specifict-business.component';
import { ModuleService } from '../module.service';

@Component({
  selector: 'app-add-business',
  templateUrl: './add-business.component.html',
  styleUrls: ['./add-business.component.css']
})
export class AddBusinessComponent implements OnInit {
  @ViewChild(FormGroupDirective, {static: false}) formGroupDirective: FormGroupDirective;
  businessForm = this.fb.group({
    name: [''],
    regNumber: [''],
    batchNumber: [''],
    pipNumber: [''],
    techAdviser: this.fb.group({
      name: [''],
      qualifications: [''],
      contacts: [''],
    }),
    category: [''],
    contacts: this.fb.group({
      address: [''],
      phone: [''],
      email: [''],
    }),
    location: this.fb.group({
      region: [''],
      district: [''],
      village: [''],
      street: [''],
      physical_location: [''],
      gps: [''],
    }),
    regType: [''],
    regStatus: [''],
    valid_from: [''],
    valid_to: [''],
  });
  categories;
  regions;
  districts;
  bulkDistricts;
  regTypes;
  regStatus;
  edit = false;
  paramId;
  adviser;
  business;

  constructor(
    private fb: FormBuilder,
    private moduleService: ModuleService,
    private snackBar: MatSnackBar,
    private active: ActivatedRoute,
    private route: Router) {
     this.active.params.subscribe( params => this.paramId = params['id']);

     if (this.paramId !== undefined) {
          this.edit = true;
     }
    }

  ngOnInit() {

      this.moduleService.getSummary().subscribe(
        (data: Summary) => {
           this.categories = data.category;
           this.regions = data.regions;
           this.districts = data.districts;
           this.bulkDistricts = data.districts;
           this.regStatus = data.status;
           this.regTypes = data.types;
        });

      if (this.edit) {
      this.moduleService.getBusiness(this.paramId).subscribe(
        (data: Data) => {
         console.log(data);
         this.business = data.business;
         this.adviser = data.adviser;
         if (data.adviser !== null) {
          this.businessForm.get('techAdviser').get('name').setValue(data.adviser.name);
         }
         if (data.adviser !== null) {
          this.businessForm.get('techAdviser').get('qualifications').setValue(data.adviser.qualification);
         }
         if (data.adviser !== null) {
          this.businessForm.get('techAdviser').get('contacts').setValue(data.adviser.contact);
         }
         if (data.business.name !==  null) {
          this.businessForm.get('name').setValue(data.business.name);
         }
         if (data.business.pipNumber !==  null) {
          this.businessForm.get('pipNumber').setValue(data.business.pipNumber);
         }
         if (data.business.batchNumber !==  null) {
          this.businessForm.get('batchNumber').setValue(data.business.batchNumber);
         }
         if (data.business.regNumber !== null) {
          this.businessForm.get('regNumber').setValue(data.business.regNumber);
         }
         if (data.business.Valid_from !== null) {
          this.businessForm.get('valid_from').setValue(data.business.Valid_from);
         }
         if (data.business.Valid_to !== null) {
          this.businessForm.get('valid_to').setValue(data.business.Valid_to);
         }
         if (data.business.village !== null) {
          this.businessForm.get('location').get('village').setValue(data.business.village);
         }
         if (data.business.street !== null) {
          this.businessForm.get('location').get('street').setValue(data.business.street);
         }
         if (data.business.physical_location !== null) {
          this.businessForm.get('location').get('physical_location').setValue(data.business.physical_location);
         }
         if (data.business.gps !== null) {
          this.businessForm.get('location').get('gps').setValue(data.business.gps);
         }
         if (data.business.address !== null) {
          this.businessForm.get('contacts').get('address').setValue(data.business.address);
         }
         if (data.business.phone !== null) {
          this.businessForm.get('contacts').get('phone').setValue(data.business.phone);
         }
         if (data.business.email !== null) {
          this.businessForm.get('contacts').get('email').setValue(data.business.email);
         }
         if (data.business.regTypeId !== null) {
          this.businessForm.controls['regType'].setValue(data.business.regTypeId);
         }
         if (data.business.categoryId !== null) {
          this.businessForm.controls['category'].setValue(data.business.categoryId);
         }
         if (data.business.regStatusId !== null) {
          this.businessForm.controls['regStatus'].setValue(data.business.regStatusId);
         }
         if (data.business.regionId !== null) {
          this.businessForm.controls['location'].get('region').setValue(data.business.regionId);
         }
         if (data.business.districtId !== null) {
          this.businessForm.controls['location'].get('district').setValue(data.business.districtId);
         }
        }
      );
    }


  }

  errorMessage() {
    return 'Field is required';
  }

  onChooseRegion(regionId) {
    const districts = [];
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.bulkDistricts.length; i++ ) {

    if (this.bulkDistricts[i].region_id === regionId) {
        districts.push(this.bulkDistricts[i]);
      }

    }

    this.districts = districts;
  }

  onSubmit() {
    this.moduleService.registerBusiness(this.businessForm.value).subscribe(
      (data) => {
        this.onOpenSnackBar('Business successfully registered');
        this.businessForm.reset();
        this.formGroupDirective.resetForm();
      }
    );
  }
  onUpdate() {
    console.log(this.businessForm.value);
    const data = {
     businessId: this.business.id,
     adviserId: this.business.adviserId,
     data: this.businessForm.value
    };
    this.moduleService.updateBusiness(data).subscribe(
      // tslint:disable-next-line: no-shadowed-variable
      (data) => {
        console.log(data);
        this.onOpenSnackBar('Business successfully updated');
      }
    );
  }

  onOpenSnackBar(message: string) {
    this.snackBar.open(message, '', {duration: 3000});
  }

}

export interface Summary {
  category;
  regions;
  districts;
  types;
  status;
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';

const baseUrl = environment.apiUrl;
const url1 = baseUrl + '/dash-stats';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
headers = new HttpHeaders({'Content-Type': 'application/json', 'X-Requested-Width': 'XMLHttpRequeest'});

  constructor(private http: HttpClient) { }

 getDashStats() {
    return this.http.get(url1, {headers: this.headers});
 }

  bigChart() {
    return [{
      name: 'Endrin',
      data: [502, 635, 809, 947, 1402, 3634, 5268]
  }, {
      name: 'Daplon',
      data: [106, 107, 111, 133, 221, 767, 1766]
  }, {
      name: 'Propanil',
      data: [163, 203, 276, 408, 547, 729, 628]
  }, {
      name: 'Alachlor',
      data: [18, 31, 54, 156, 339, 818, 1201]
  }, {
      name: 'Atrazine',
      data: [2, 2, 2, 6, 13, 30, 46]
  }];
  }

  cards() {
    return [ 78, 34, 39, 83];

  }

  pie() {
    return [{
      name: 'Chrome',
      y: 61.41,
      sliced: true,
      selected: true
  }, {
      name: 'Internet Explorer',
      y: 11.84
  }, {
      name: 'Firefox',
      y: 10.85
  }, {
      name: 'Edge',
      y: 4.67
  }, {
      name: 'Safari',
      y: 4.18
  }, {
      name: 'Sogou Explorer',
      y: 1.64
  }, {
      name: 'Opera',
      y: 1.6
  }, {
      name: 'QQ',
      y: 1.2
  }, {
      name: 'Other',
      y: 2.61
  }];
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from 'src/app/modules/dashboard/dashboard.component';
import { DefaultComponent } from './default.component';
import { RouterModule } from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatSidenavModule, MatDividerModule, MatCardModule,
   MatSortModule, MatButtonModule, MatIconModule, MatFormFieldModule, MatInputModule, MatSelectModule,
   MatDialogModule,
   MatTooltipModule,
   MatSnackBarModule,
   MatProgressSpinnerModule,
   MatPaginatorModule,
   MatChipsModule,
   MatAutocompleteModule} from '@angular/material';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { FlexLayoutModule} from '@angular/flex-layout';
import { DashboardService } from 'src/app/modules/dashboard/dashboard.service';
import { HighchartsChartModule} from 'highcharts-angular';
import { BusinessesComponent } from 'src/app/modules/business/businesses/businesses.component';
import {MatTableModule} from '@angular/material/table';
import { AddBusinessComponent } from 'src/app/modules/business/add-business/add-business.component';
import { SpecifictBusinessComponent } from 'src/app/modules/business/specifict-business/specifict-business.component';
import { PesticidesComponent } from 'src/app/modules/pesticides-module/pesticides/pesticides.component';
import { AddPesticideComponent } from 'src/app/modules/pesticides-module/add-pesticide/add-pesticide.component';
import { SpecificPesticideComponent } from 'src/app/modules/pesticides-module/specific-pesticide/specific-pesticide.component';
import { AddInspectionComponent } from 'src/app/modules/inspection/add-inspection/add-inspection.component';
import { InspectionsComponent } from 'src/app/modules/inspection/inspections/inspections.component';
import { SpecificInspectionComponent } from 'src/app/modules/inspection/specific-inspection/specific-inspection.component';
import { StocksComponent } from 'src/app/modules/stock/stocks/stocks.component';
import { SpecificStockComponent } from 'src/app/modules/stock/specific-stock/specific-stock.component';
import { MessagesComponent } from 'src/app/modules/communication/messages/messages.component';
import { AddMessageComponent } from 'src/app/modules/communication/add-message/add-message.component';
import { UsersComponent } from 'src/app/modules/users-module/users/users.component';
import { AddUserComponent } from 'src/app/modules/users-module/add-user/add-user.component';
import { SpecificUserComponent } from 'src/app/modules/users-module/specific-user/specific-user.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { AddStockComponent } from 'src/app/modules/stock/add-stock/add-stock.component';
import { ChangePasswordComponent } from 'src/app/modules/shared/change-password/change-password.component';
import { ManageProfileComponent } from 'src/app/modules/shared/manage-profile/manage-profile.component';
import { StockDetailsComponent } from 'src/app/modules/stock/stock-details/stock-details.component';

@NgModule({
  declarations: [
    DefaultComponent,
    DashboardComponent,
    BusinessesComponent,
    AddBusinessComponent,
    MessagesComponent,
    AddMessageComponent,
    SpecifictBusinessComponent,
    PesticidesComponent,
    AddPesticideComponent,
    SpecificPesticideComponent,
    AddInspectionComponent,
    InspectionsComponent,
    SpecificInspectionComponent,
    StocksComponent,
    SpecificStockComponent,
    UsersComponent,
    AddUserComponent,
    SpecificUserComponent,
    AddStockComponent,
    ManageProfileComponent,
    ChangePasswordComponent,
    StockDetailsComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    MatSidenavModule,
    MatDividerModule,
    MatCardModule,
    FlexLayoutModule,
    HighchartsChartModule,
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatCheckboxModule
  ],
  providers: [
    DashboardService
  ],
  entryComponents: [
    AddMessageComponent,
    AddUserComponent,
    ChangePasswordComponent,
    ManageProfileComponent
  ]
})
export class DefaultModule { }

import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {FormControl} from '@angular/forms';
import { Router } from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import { ModuleService } from '../../business/module.service';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})

export class UsersComponent  implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  displayedColumns: string[] = ['position', 'name', 'username', 'role', 'phone', 'email', 'actions'];
  dataSource;
  clear = false;
  uploading = false;
  users: Inspection[];

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    private route: Router,
    public moduleService: ModuleService,
    private http: HttpClient
    ) {
      console.log(this.moduleService.isInspector);
    }

  ngOnInit() {
    this.moduleService.getUsers().subscribe(
      (data: Inspection[]) => {
       this.users = data;
       this.dataSource = new MatTableDataSource(this.users);
       this.dataSource.sort = this.sort;
       this.dataSource.paginator = this.paginator;
      }
    );
  }
  showAdd() {
    if (this.moduleService.isRegistrar) {
      return false;
    }
    if (this.moduleService.isInspector) {
      return false;
    }
    return true;
  }

  // clearFilter() {
  //   this.inspections = this.bulkInspections;
  //   this.dataSource = new MatTableDataSource(this.inspections);
  //   this.clear = false;
  // }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onAddUser() {
    this.route.navigate(['app/add-user']);
  }
  toDetails(id) {
    this.route.navigate(['app/specific-user', {id}]);
  }

  toEdit(id) {
    this.route.navigate(['app/add-user', {id}]);
  }

  onExport() {
    this.moduleService.exportUsers();
  }

  onChooseFile(event) {
    this.uploading = true;
    const url = environment.apiUrl + '/import-users';
    const file: File = event.target.files[0];
    let data: FormData = new FormData();
    data.append('fileToUpload', file);
    this.http.post(url, data).subscribe(
      response => {
    this.moduleService.getUsers().subscribe(
          (data: Inspection[]) => {
           this.users = data;
           this.dataSource = new MatTableDataSource(this.users);
           this.dataSource.sort = this.sort;
           this.dataSource.paginator = this.paginator;
          }
        );
    this.uploading = false;
   },
   error => {
    Swal.fire({
      text: 'File was not imported..Use the required file format.',
      icon: 'info'
    });
   }
   );
  }

}

export interface Inspection {
   firstname;
   middlename;
   lastname;
   role;
   phone;
   email;
}



import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModuleService } from '../module.service';

@Component({
  selector: 'app-specifict-business',
  templateUrl: './specifict-business.component.html',
  styleUrls: ['./specifict-business.component.css']
})
export class SpecifictBusinessComponent implements OnInit {
  id;
  business;
  advicer;

  constructor(
    public moduleService: ModuleService,
    private active: ActivatedRoute,
    private router: Router
  ) {
    this.active.params.subscribe( params => this.id = params['id']);
  }

  ngOnInit() {
    this.moduleService.getBusiness(this.id).subscribe(
      (data: Data) => {
        this.business = data.business;
        this.advicer = data.adviser;
      }
    );
  }

  onEdit(id) {
    this.router.navigate(['app/add-business', {id}]);
  }

}

export interface Data {
 adviser;
 business;
}

import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {FormControl, Validators, FormBuilder, FormArray, FormGroup} from '@angular/forms';
import { Router } from '@angular/router';
import { StockService } from '../stock.service';
import {MatPaginator} from '@angular/material/paginator';
import { ModuleService } from '../../business/module.service';

@Component({
  selector: 'app-stocks',
  templateUrl: './stocks.component.html',
  styleUrls: ['./stocks.component.css']
})
export class StocksComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  // tslint:disable-next-line: max-line-length
  displayedColumns: string[] = ['position', 'pesticide', 'importer', 'permit', 'batch', 'location', 'validity', 'status', 'quantity', 'remained', 'unit', 'actions'];
  dataSource;
  stocks: Stock[];
  location = new FormControl('');
  pesticide = new FormControl('');
  importer = new FormControl('');
  status = new FormControl('');
  locations;
  pesticides;
  importers;
  bulkStock: Stock[];
  clear = false;
  statuses = [
    'valid',
    'expired'
  ];
  date = new Date();
  date2 = this.date.getFullYear() + '-' + this.date.getMonth() + '-' + this.date.getDate();

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    private route: Router,
    private stockService: StockService,
    public moduleService: ModuleService) { }

  ngOnInit() {
      this.getStock();
      this.getSummary();
 }

 getSummary() {
   this.stockService.getSummary().subscribe(
     (data: Summary) => {
       this.locations = data.regions;
       this.pesticides = data.pesticides;
       this.importers = data.importers;     }
   );
 }

 getStock() {
  this.stockService.getStocks().subscribe(
    (data: Stock[]) => {
     this.stocks = data;
     this.bulkStock = data;
     this.dataSource = new MatTableDataSource(this.stocks);
     this.dataSource.sort = this.sort;
     this.dataSource.paginator = this.paginator;
    }
  );
 }

  onExport() {
    this.stockService.export();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  clearFilter() {
    this.stocks = this.bulkStock;
    this.dataSource = new MatTableDataSource(this.stocks);
    this.clear = false;
  }

  getDate(date) {
    return new Date(date);
   }

  getValidity(expDate) {
    if (this.getDate(this.date2) >= this.getDate(expDate)) {
      return 'expired';
    }
    if (this.getDate(this.date2) < this.getDate(expDate)) {
      return 'valid';
    }
  }

  showAdd() {
    if (this.moduleService.isRegistrar) {
      return false;
    }
    if (this.moduleService.isInspector) {
      return false;
    }
    return true;
  }

  toDetails(id) {
    this.route.navigate(['app/stock-details', {id}]);
  }

  groupFilter() {
    const date = new Date();
    const date2 = date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate();
    console.log(typeof date2);
    console.log(typeof this.bulkStock[0].exp_date);
    let result: Stock[] = [];
    if (this.pesticide.value !== '' && this.location.value !== '' && this.status.value !== '' && this.importer.value !== '') {
    result = this.bulkStock.filter(stock => stock.trade_name === this.pesticide.value
       && stock.regionId === this.location.value.toUpperCase()
       && stock.name === this.importer.value
       //status missing
       );
    } else {
      if (this.pesticide.value !== '' && this.location.value !== '' && this.importer.value !== '') {
        result = this.bulkStock.filter(stock => stock.trade_name === this.pesticide.value
           && stock.regionId === this.location.value.toUpperCase()
           && stock.name === this.importer.value
           );
        } else {
          if (this.pesticide.value !== '' && this.location.value !== '' && this.status.value !== '') {
            result = this.bulkStock.filter(stock => stock.trade_name === this.pesticide.value
               && stock.regionId === this.location.value.toUpperCase()
              //  && stock.name === this.importer.value
               );
            } else {
              if (this.pesticide.value !== '' && this.location.value !== '') {
                result = this.bulkStock.filter(stock => stock.trade_name === this.pesticide.value
                   && stock.regionId === this.location.value.toUpperCase()
                   );
                } else {
                  if (this.pesticide.value !== '' && this.status.value !== '') {
                    result = this.bulkStock.filter(stock => stock.trade_name === this.pesticide.value
                      //  && stock.name === this.importer.value
                       );
                    } else {
                      if (this.pesticide.value !== '' && this.importer.value !== '') {
                        result = this.bulkStock.filter(stock => stock.trade_name === this.pesticide.value
                           && stock.name === this.importer.value
                           );
                        } else {
                          if (this.location.value !== '' && this.importer.value !== '') {
                            result = this.bulkStock.filter(stock => stock.regionId === this.location.value.toUpperCase()
                               && stock.name === this.importer.value
                               );
                            } else {
                              if (this.location.value !== '' && this.status.value !== '') {
                                result = this.bulkStock.filter(stock => stock.regionId === this.location.value.toUpperCase()
                                  //  && stock.name === this.importer.value
                                   );
                                } else {
                                  if (this.importer.value !== '' && this.status.value !== '') {
                                    result = this.bulkStock.filter(stock => stock.name === this.importer.value
                                      //  && stock.name === this.importer.value
                                       );
                                    } else {
                                      if (this.pesticide.value !== '' ) {
                                        result = this.bulkStock.filter(stock => stock.trade_name === this.pesticide.value);
                                        } else {
                                          if (this.importer.value !== '') {
                                            result = this.bulkStock.filter(stock => stock.name === this.importer.value
                                               );
                                            } else {
                                              if (this.location.value !== '') {
                                                result = this.bulkStock.filter(stock => stock.regionId === this.location.value.toUpperCase()
                                                   );
                                                } else {
                                                  if (this.status.value !== '') {
                                                     // result = this.bulkStock.filter(stock => stock.name === this.importer.value);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    }
    console.log(result);
    this.stocks = result;
    this.dataSource = new MatTableDataSource(this.stocks);
    this.clear = true;
  }

  toSpecific(pesticide) {
    this.route.navigate(['app/specific-stock', {pesticide}]);
  }

  onAddStock() {
      this.route.navigate(['app/add-stock']);
    }

}

export interface Stock {
   name;
   regNumber;
   category;
   regStatus;
   location;
   regionId;
   trade_name;
   exp_date;
}

export interface Summary {
  pesticides;
  regions;
  importers;
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './layouts/default/default.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { BusinessesComponent } from './modules/business/businesses/businesses.component';
import { AddBusinessComponent } from './modules/business/add-business/add-business.component';
import { SpecifictBusinessComponent } from './modules/business/specifict-business/specifict-business.component';
import { PesticidesComponent } from './modules/pesticides-module/pesticides/pesticides.component';
import { AddPesticideComponent } from './modules/pesticides-module/add-pesticide/add-pesticide.component';
import { SpecificPesticideComponent } from './modules/pesticides-module/specific-pesticide/specific-pesticide.component';
import { AddInspectionComponent } from './modules/inspection/add-inspection/add-inspection.component';
import { InspectionsComponent } from './modules/inspection/inspections/inspections.component';
import { StocksComponent } from './modules/stock/stocks/stocks.component';
import { SpecificInspectionComponent } from './modules/inspection/specific-inspection/specific-inspection.component';
import { IndexComponent } from './shared/index/index.component';
import { PublicComponent } from './shared/public/public.component';
import { MessagesComponent } from './modules/communication/messages/messages.component';
import { UsersComponent } from './modules/users-module/users/users.component';
import { AuthComponent } from './shared/auth/auth.component';
import { AddUserComponent } from './modules/users-module/add-user/add-user.component';
import { SpecificUserComponent } from './modules/users-module/specific-user/specific-user.component';
import { SpecificStockComponent } from './modules/stock/specific-stock/specific-stock.component';
import { AddStockComponent } from './modules/stock/add-stock/add-stock.component';
import { StockDetailsComponent } from './modules/stock/stock-details/stock-details.component';


const routes: Routes = [
  { path : '',
  component: IndexComponent,
  },
  { path : 'tpri',
  component: IndexComponent,
  },
   {
     path: 'public',
     component: PublicComponent,
     children : [
      { path : 'add-business',
      component: AddBusinessComponent
      },
      { path : 'pesticides',
      component: PesticidesComponent
      },
      { path : 'specific-pesticide',
      component: SpecificPesticideComponent
      },
      { path : 'businesses',
      component: BusinessesComponent,
      },
      { path : 'specific-business',
      component: SpecifictBusinessComponent
      },
      { path : 'login',
      component: AuthComponent,
      }
     ]
   },
  {
    path: 'app',
    component: DefaultComponent,
    children: [
    { path : '',
      component: DashboardComponent
    },
    { path : 'businesses',
    component: BusinessesComponent,
    },
    { path : 'add-business',
    component: AddBusinessComponent
    },
    { path : 'specific-business',
    component: SpecifictBusinessComponent
    },
    { path : 'pesticides',
    component: PesticidesComponent
    },
    { path : 'add-pesticide',
    component: AddPesticideComponent
    },
    { path : 'specific-pesticide',
    component: SpecificPesticideComponent
    },
    { path : 'add-inspection',
    component: AddInspectionComponent
    },
    { path : 'inspections',
    component: InspectionsComponent
    },
    { path : 'specific-inspection',
    component: SpecificInspectionComponent
    },
    { path : 'stock',
    component: StocksComponent
    },
    { path : 'add-stock',
    component: AddStockComponent
    },
    { path : 'stock-details',
    component: StockDetailsComponent
  },
    { path : 'specific-stock',
    component: SpecificStockComponent
    },
    { path : 'messages',
    component: MessagesComponent
    },
    { path : 'users',
    component: UsersComponent
    },
    { path : 'add-user',
    component: AddUserComponent
    },
    { path : 'specific-user',
    component: SpecificUserComponent
    }
  ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

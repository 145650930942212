import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {FormControl, Validators, FormBuilder, FormArray, FormGroup} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ModuleService } from '../module.service';
import {MatPaginator} from '@angular/material/paginator';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { SelectionModel } from '@angular/cdk/collections';
import { AddMessageComponent } from '../../communication/add-message/add-message.component';
import { ImportDialogComponent } from '../../shared/import-dialog/import-dialog.component';
import { DeleteDataComponent } from 'src/app/shared/components/delete-data/delete-data.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-businesses',
  templateUrl: './businesses.component.html',
  styleUrls: ['./businesses.component.css']
})
export class BusinessesComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  displayedColumns: string[] = ['select', 'name', 'category', 'location', 'regStatus', 'actions'];
  displayedColumnsIsNotAuth: string[] = ['position', 'name', 'category', 'location', 'regStatus', 'actions'];
  initialSelection = [];
  allowMultiSelect = true;
  selection = new SelectionModel<Business>(this.allowMultiSelect, this.initialSelection);
  dataSource;
  businesses: Business[];
  bulkBusinesses: Business[];
  name = new FormControl('');
  location = new FormControl('');
  category = new FormControl('');
  status = new FormControl('');
  clear = false;
  names;
  locations;
  categories;
  statuses;
  uploading = false;
  isAuth;
  paramCategory;
  paramStatus;

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    private route: Router,
    private active: ActivatedRoute,
    public moduleService: ModuleService,
    private http: HttpClient) {
      this.isAuth = moduleService.isAuth();
      this.active.paramMap.subscribe(params => this.paramCategory = params.get('category'));
      this.active.paramMap.subscribe(params => this.paramStatus = params.get('status'));
      if (this.paramCategory !== null) {
          this.category.setValue(this.paramCategory);
      }
      if (this.paramStatus !== null) {
        this.status.setValue(this.paramStatus);
    }

    }

  ngOnInit() {
    this.moduleService.getBusinesses().subscribe(
      (data: Business[]) => {
       this.businesses = data;
       this.bulkBusinesses = data;
       this.dataSource = new MatTableDataSource(this.businesses);
       this.dataSource.sort = this.sort;
       this.dataSource.paginator = this.paginator;
       if (this.paramCategory !== null) {
         this.applyGroupFilter();
       }
       if (this.paramStatus !== null) {
        this.applyGroupFilter();
      }

      }
    );
    this.moduleService.getFilterValues().subscribe(
     (data: Data) => {
       this.names = data.names;
       this.locations = data.regions;
       this.categories = data.categories;
       this.statuses = data.statuses;
     }
   );
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  showAdd() {
    if (this.moduleService.isRegistrar) {
      return false;
    }
    if (this.moduleService.isInspector) {
      return false;
    }
    return true;
  }
 isAllSelected() {
  const numSelected = this.selection.selected.length;
  const numRows = this.dataSource.data.length;
  return numSelected === numRows;
 }
 masterToggle() {
  this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
 }
 onSendEmail(): void {
  const dialogRef = this.dialog.open( AddMessageComponent, {
    width: '650px',
    height: '400px',
    data: {businesses: this.selection.selected},
  });
  dialogRef.afterClosed().subscribe(result => {
       console.log('closed');
       this.masterToggle();
       this.masterToggle();
  });
}
  clearFilter() {
    this.clear = false;
    this.name.setValue('');
    this.location.setValue('');
    this.category.setValue('');
    this.status.setValue('');
    this.businesses = this.bulkBusinesses;
    this.dataSource = new MatTableDataSource(this.businesses);
  }

  applyGroupFilter() {
    let value: Business[] = [];
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.bulkBusinesses.length; i++) {
      if (this.location.value !== '' && this.category.value !== '' && this.status.value !== '' ) {
       if (this.bulkBusinesses[i].regionId === this.location.value.toUpperCase() &&
           this.bulkBusinesses[i].categoryId === this.category.value && this.bulkBusinesses[i].regStatusId === this.status.value) {
         value.push(this.bulkBusinesses[i]);
        }
        } else {
          if (this.location.value !== '' && this.category.value !== '' && this.status.value !== '' ) {
            if (this.bulkBusinesses[i].regionId.toLowerCase() === this.location.value.toLowerCase() &&
                this.bulkBusinesses[i].categoryId === this.category.value && this.bulkBusinesses[i].regStatusId === this.status.value) {
              value.push(this.bulkBusinesses[i]); }
            } else {
              if (this.category.value !== '' && this.status.value !== '' ) {
                if (this.bulkBusinesses[i].categoryId.toLowerCase() === this.category.value.toLowerCase() &&
                this.bulkBusinesses[i].regStatusId === this.status.value) {
                  value.push(this.bulkBusinesses[i]); }
                } else {
                  if (this.category.value !== '' && this.location.value !== '' ) {
                    if(this.bulkBusinesses[i].regionId !== null) {
                      if (this.bulkBusinesses[i].categoryId.toLowerCase() === this.category.value.toLowerCase() &&
                    this.bulkBusinesses[i].regionId.toUpperCase() === this.location.value.toUpperCase() ) {
                      value.push(this.bulkBusinesses[i]); }
                    }
                    } else {
                      if (this.status.value !== '' ) {
                        if (this.bulkBusinesses[i].regStatusId === this.status.value) {
                          value.push(this.bulkBusinesses[i]); }
                        } else {
                          if (this.category.value !== '' ) {
                            if (this.bulkBusinesses[i].categoryId.toLowerCase() === this.category.value.toLowerCase()) {
                              value.push(this.bulkBusinesses[i]); }
                            } else {
                              if (this.location.value !== '' ) {
                                if (this.bulkBusinesses[i].regionId === this.location.value.toUpperCase()) {
                                  value.push(this.bulkBusinesses[i]); }
                                } else {
                                  if (this.name.value !== '' ) {
                                    if (this.bulkBusinesses[i].name === this.name.value) {
                                      value.push(this.bulkBusinesses[i]); }}
                                }    }
                        }

                    }
            }
            }
        }
    }
    this.businesses = value;
    this.dataSource = new MatTableDataSource(this.businesses);
    this.clear = true;
  }

  onAddBussiness() {
    this.route.navigate(['app/add-business']);
  }
  toDetails(id) {
    this.route.navigate(['app/specific-business', {id}]);
  }

  toDetailsNotAuth(id) {
    this.route.navigate(['public/specific-business', {id}]);
  }
  onEdit(id) {
    this.route.navigate(['app/add-business', {id}]);
  }

  onDelete(id) {
    const dialogRef = this.dialog.open(DeleteDataComponent, {
      width: '500px',
      height: '300px',
      data: {id: id, type: 'business'}
    });

    dialogRef.afterClosed().subscribe(data =>{
      this.ngOnInit();
    });
  }

  onInspection(id, category) {
    this.route.navigate(['app/add-inspection', {id, category}]);
  }
  onExport() {
    this.moduleService.export();
  }
  onImport() {
    const dialogRef = this.dialog.open(ImportDialogComponent, {
      width: '500px',
      height: '250px',
      data: {type: 'business'}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.moduleService.getBusinesses().subscribe(
        (data: Business[]) => {
          this.businesses = data;
          this.bulkBusinesses = data;
          this.dataSource = new MatTableDataSource(this.businesses);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        }
      );
    });
   }
  onChooseFile(event) {
    this.uploading = true;
    // const url = 'http://127.0.0.1:8000/api/import-businesses';
    const url = environment.apiUrl + '/import-businesses';
    const file: File = event.target.files[0];
    let data: FormData = new FormData();
    data.append('fileToUpload', file);
    this.http.post(url, data).subscribe(
    response => {
    this.moduleService.getBusinesses().subscribe(
      (data: Business[]) => {
       this.businesses = data;
       this.bulkBusinesses = data;
       this.dataSource = new MatTableDataSource(this.businesses);
       this.dataSource.sort = this.sort;
       this.dataSource.paginator = this.paginator;
      }
    );
    this.uploading = false;
   },
   error => {
    console.log(error);
    Swal.fire({
      text: 'File was not imported..Use the required file format.',
      icon: 'info'
    });
    this.uploading = false;
   });
      }
}

export interface Business {
   name;
   regNumber;
   regionId;
   categoryId;
   regStatusId;
   location;
}

export interface Data {
  names;
  categories;
  statuses;
  regions;
  districts;
}

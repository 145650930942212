import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { environment } from 'src/environments/environment';

export interface DialogData {
  cropId;
  type;
}

@Component({
  selector: 'app-import-dialog',
  templateUrl: './import-dialog.component.html',
  styleUrls: ['./import-dialog.component.css']
})
export class ImportDialogComponent implements OnInit {
  file: File;
  uploading = false;

  constructor(
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<ImportDialogComponent>,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit() { }

  exportPesticideSample() {
    const link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = 'assets/pesticide_format.xlsx';
    link.download = 'pesticide_format.xlsx';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  exportBusinessSample() {
    const link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = 'assets/business_format.xlsx';
    link.download = 'business_format.xlsx';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onChooseFile(event) {
    this.file = event.target.files[0];
  }

   onImportPesticide() {
    // const url = 'http://127.0.0.1:8000/api/import-pesticides';
    const url = environment.apiUrl + '/import-pesticides';
    let data: FormData = new FormData();
    data.append('fileToUpload', this.file);
    this.http.post(url, data).subscribe(
    response => {
    this.uploading = false;
    this.onNoClick();
   },
   error => {
    console.log(error);
    this.uploading = false;
    Swal.fire({
      text: 'File was not imported..Use the required file format.',
      icon: 'info'
    });
   });
   }

   onImportBusiness() {
    this.uploading = true;
    // const url = 'http://127.0.0.1:8000/api/import-businesses';
    const url = environment.apiUrl + '/import-businesses';
    let data: FormData = new FormData();
    data.append('fileToUpload', this.file);
    this.http.post(url, data).subscribe(
    response => {
      this.uploading = false;
      this.onNoClick();
   },
   error => {
    console.log(error);
    this.uploading = false;
    Swal.fire({
      text: 'File was not imported..Use the required file format.',
      icon: 'info'
    });
   });
      }

}

import { Component, OnInit } from '@angular/core';
import { AddMessageComponent } from '../add-message/add-message.component';
import {MatDialog} from '@angular/material/dialog';
import { MessageService } from '../message.service';
import { ModuleService } from '../../business/module.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css']
})
export class MessagesComponent implements OnInit {
  displayedColumns: string[] = ['to', 'message', 'date'];
  messages: Message[];
  dataSource;

  constructor(
    public dialog: MatDialog,
    private messageService: MessageService,
    private businessService: ModuleService
  ) { }

  ngOnInit() {
    this.messageService.getMessages().subscribe(
      (data: Message[]) => {
        this.messages = data;
        this.dataSource = this.messages;
      }
    );
  }

  requiredDate(value) {
   const dt = new Date(value);
   const date = dt.getDate();
   const month = dt.toLocaleString('default', { month: 'long' });
   const date2 = month + ' ' + date;
   return date2;
  }

  onCompose(): void {
    const dialogRef = this.dialog.open( AddMessageComponent, {
      width: '950px',
      height: '450px',
    });

    dialogRef.afterClosed().subscribe(result => {
         console.log('close compose');
    }); 
  }

}
export interface Message {
 to;
 sender;
 message;
 date;
}


import { Component, OnInit , ViewChild} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Validators, FormGroupDirective} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { PesticideService } from '../pesticide.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-add-pesticide',
  templateUrl: './add-pesticide.component.html',
  styleUrls: ['./add-pesticide.component.css']
})
export class AddPesticideComponent implements OnInit {
  @ViewChild(FormGroupDirective, {static: false}) formGroupDirective: FormGroupDirective;

  pesticideForm = this.fb.group({
    trade_name: ['', Validators.required],
    common_name:  ['', Validators.required],
    pesticide_type: ['', Validators.required],
    reg_number: ['', Validators.required],
    registrant:  ['', Validators.required],
    usage:  ['', Validators.required],
    reg_type:  ['', Validators.required],
    reg_status:  ['', Validators.required],
    valid_from:  ['', Validators.required],
    valid_to:  ['', Validators.required],
  });
  pesticides = [
    {value: '', viewValue: ''}
  ];
  edit = false;
  regStatus;
  regTypes;
  typee;
  registrants;
  paramId;
  pesticide;
  regs = [
    {name: 'Acaricides(AC) ', value: 'AC'},
    {name: 'Avicides(AV)', value: 'AV' },
    {name: 'Biological Control Agent (BCA)', value: 'BCA'},
    {name: 'Fungicides(FU)', value: 'FU' },
    {name: 'Growth Regulators(GR)', value: 'GR'},
    {name: 'Herbicides(HE)', value: 'HE' },
    {name: 'Molluscides(MO)', value: 'MO'},
    {name: 'Nematides(NE)', value: 'NE'},
    {name: 'Restricted(RE)', value: 'RE'},
    {name: 'Rodenticides(RO)', value: 'RO' },
    {name: 'Insecticides(IN)', value: 'IN' }
  ];

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private active: ActivatedRoute,
    private pesticideService: PesticideService,
    private loc: Location,
    private route: Router
  ) {
    this.active.params.subscribe( params => this.paramId = params['id']);

    if (this.paramId !== undefined) {
         this.edit = true;
    }
  }

  ngOnInit() {
    this.pesticideService.getSummary().subscribe(
      (data: Summary) => {
        this.regStatus = data.status;
        this.regTypes = data.types;
        this.typee = data.typee;
        this.registrants = data.registrants;
      }
    );

    if (this.edit) {
     this.pesticideService.getPesticide(this.paramId).subscribe(
       (data: Data) => {
        this.pesticide = data;
        this.pesticideForm.get('trade_name').setValue(data.trade_name);
        this.pesticideForm.get('common_name').setValue(data.common_name);
        this.pesticideForm.get('reg_number').setValue(data.reg_number);
        this.pesticideForm.get('reg_status').setValue(data.reg_status);
        this.pesticideForm.get('reg_type').setValue(data.reg_type);
        this.pesticideForm.get('valid_from').setValue(data.valid_from);
        this.pesticideForm.get('valid_to').setValue(data.valid_to);
        this.pesticideForm.get('usage').setValue(data.usage);
        this.pesticideForm.get('registrant').setValue(data.registrant);
       }
     );
    }
  }

  setPesticideType(value) {
    this.pesticideForm.get('pesticide_type').setValue(value);
  }

  errorMessage() {
    return 'Field is required';
  }

  onSubmit() {
    console.log(this.pesticideForm.value)
    this.pesticideService.registerPesticide(this.pesticideForm.value).subscribe(
      (data) => {
        this.onOpenSnackBar('pesticide successfully registered');
        this.route.navigate(['app/specific-pesticide', {data}]);
        this.pesticideForm.reset();
        this.formGroupDirective.resetForm();
      }
    );
  }

  goBack() {
    this.loc.back();
  }

  onUpdate() {
    const data = {
      data: this.pesticideForm.value,
      pesticideId: this.pesticide.id
    };
    if(this.pesticideForm.valid) {
      this.pesticideService.updatePesticide(data).subscribe(
        (data) => {
          this.onOpenSnackBar('Pesticide successfully updated');
        }
      );
    }
  }

  onOpenSnackBar(message: string) {
    this.snackBar.open(message, '', { duration: 3000});
  }

}
export interface Summary {
  types;
  status;
  registrants;
  typee;
}

export interface Data {
  trade_name;
  common_name;
  reg_number;
  reg_type;
  reg_status;
  valid_from;
  valid_to;
  registrant;
  usage;
}

import { Component, OnInit, Output, EventEmitter  } from '@angular/core';
import { Router } from '@angular/router';
import { ModuleService } from 'src/app/modules/business/module.service';
import { ManageProfileComponent } from 'src/app/modules/shared/manage-profile/manage-profile.component';
import { ChangePasswordComponent } from 'src/app/modules/shared/change-password/change-password.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Output() toggleSideBarforMe: EventEmitter<any> = new EventEmitter();
   username = this.moduleService.getUsername();
   userId = this.moduleService.getId();

  constructor(
    private route: Router,
    private moduleService: ModuleService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
  }
  toggleSidebar() {
 this.toggleSideBarforMe.emit();
 setTimeout(() => {
  window.dispatchEvent(
    new Event('resize')
  );
  }, 300);
  }

  signOut() {
    this.moduleService.removeCredentials();
    this.route.navigate(['/']);
  }

  onChangePassword(): void {
    const dialogRef = this.dialog.open( ChangePasswordComponent, {
      width:  '450px',
      height: '420px',
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  onManageProfile(): void {
      this.route.navigate(['app/add-user', {id : this.userId, type: 'manage'}]);
    // const dialogRef = this.dialog.open( ManageProfileComponent, {
    //   width: '950px',
    //   height: '450px',
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //      console.log('close compose');
    // });
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';

const baseUrl = environment.apiUrl;
const url1 = baseUrl + '/get-stocks';
const url2 = baseUrl + '/export-stocks';
const url3 = baseUrl + '/specific-stock';
const url4 = baseUrl + '/stock-summary';
const url5 = baseUrl + '/stock-add';
const url6 = baseUrl + '/get-stock/';
const url7 = baseUrl + '/get-previous-stock';

@Injectable({
  providedIn: 'root'
})
export class StockService {
  private headers = new HttpHeaders({'Content-Type': 'application/json'});

  constructor(private http: HttpClient) { }

  getStocks() {
    return this.http.get (url1 , {headers: this.headers});
  }

  addStock(data) {
    return this.http.post(url5, data, {headers: this.headers});
  }

  getSpecificStock(data) {
    return this.http.post(url3, data, {headers: this.headers});
  }

  getSummary() {
    return this.http.get(url4 , {headers: this.headers});
  }

  getStock(id) {
    return this.http.get (url6 + id , {headers: this.headers});
  }

  getPreviousStock(data) {
    return this.http.post (url7, data, {headers: this.headers});
  }

  export() {
    window.open(url2);
  }
}

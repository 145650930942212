import { Component, OnInit , ViewChild} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Validators, FormGroupDirective, FormArray} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import { ActivatedRoute, Router} from '@angular/router';
import { InspectionService } from '../inspection.service';
import {formatDate} from '@angular/common';
import {Location} from '@angular/common';
import { isEmpty } from 'rxjs/operators';
import { ModuleService } from '../../business/module.service';

@Component({
  selector: 'app-add-inspection',
  templateUrl: './add-inspection.component.html',
  styleUrls: ['./add-inspection.component.css']
})
export class AddInspectionComponent implements OnInit {
  @ViewChild(FormGroupDirective, {static: false}) formGroupDirective: FormGroupDirective;

  stockForm = this.fb.group({
    pesticide: ['', Validators.required],
    origin_country: ['', Validators.required],
    batch_number: ['', Validators.required],
    quantity_imported: ['', Validators.required],
    quantity_sold: ['', Validators.required],
    stock_remained: ['', Validators.required],
    validity: ['', Validators.required]
  });

  inspectionForm = this.fb.group({
    date: ['', Validators.required],
    firm:  ['', Validators.required],
    firmId: [''],
    inspectorId: [''],
    stock: this.fb.array([
      this.fb.group({
        pesticide: ['', Validators.required],
        origin_country: ['', Validators.required],
        batch_number: ['', Validators.required],
        quantity_imported: ['', Validators.required],
        quantity_sold: ['', Validators.required],
        unit: ['', Validators.required],
        stock_remained: [''],
        validity: ['', Validators.required]
      })
    ]),
    offence: this.fb.group({
      offence: ['', Validators.required],
      offence_details: [''],
      action_taken: [''],
    })
  });
  inspectorId;
  edit = false;
  paramId;
  inspection;
  stock;
  offence;
  firm;
  firms;
  pesticides;
  paramEdit;
  countries;
  firmId = 0;
  firmCategory;
  offences = [
    {value: 'YES'},
    {value: 'NO'}
  ];
  validities = [
    'Yes',
    'No'
  ];
  actions = [
    'Licence revocked',
    'Business closed',
    'Given warning',
    'Taken to court'
  ];
  timeout: any = null;
  date = new Date();
  date2 = this.date.getFullYear() + '-' + this.date.getMonth() + '-' + this.date.getDate();

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private active: ActivatedRoute,
    private inspectionService: InspectionService,
    private loc: Location,
    private router: Router,
    private moduleService: ModuleService
  ) {
    this.inspectorId = this.moduleService.getId();
    this.inspectionForm.get('inspectorId').setValue(this.inspectorId);
    this.active.params.subscribe( params => {
      this.paramId = params['id'],
      this.paramEdit = params['edit'],
      this.firmCategory = params['category']
    });
    if (this.paramEdit !== undefined) {
         this.edit = true;
    }
  }

  ngOnInit() {
    if (this.paramId !== undefined) {
      this.firms = [];
      this.inspectionService.getSummary(this.paramId).subscribe(
        (data: Summary) => {
          const date = formatDate(new Date(), 'yyyy/MM/dd', 'en');
          this.inspectionForm.get('date').setValue(date);
          this.firm = data.firms;
          this.inspectionForm.get('firm').setValue(data.firms.name);
          this.inspectionForm.get('firmId').setValue(this.paramId);
          this.pesticides = data.pesticides;
          this.countries = data.countries;
        }
      );
    }

    if (this.paramId === undefined) {
      this.inspectionService.getSummaryGeneral().subscribe(
        (data: Summary) => {
          const date = formatDate(new Date(), 'yyyy/MM/dd', 'en');
          this.inspectionForm.get('date').setValue(date);
          this.firms = data.firms;
          this.pesticides = data.pesticides;
          this.countries = data.countries;
        }
      );
    }
  }

  getDate(date) {
   return new Date(date);
  }
  getBatchValues(event: any, index) {
    clearTimeout(this.timeout);
    const $this = this;
    // tslint:disable-next-line: only-arrow-functions
    this.timeout = setTimeout(function() {
      if (event.keyCode !== 13) {
        const data = {
          value: event.target.value
        };
        $this.inspectionService.getBatchValues(data).subscribe(
          (data: Batch) => {
            $this.inspectionForm.get('stock').get(index.toString()).get('pesticide').setValue(data.trade_name);
            $this.inspectionForm.get('stock').get(index.toString()).get('origin_country').setValue(data.origin_country);
            $this.inspectionForm.get('stock').get(index.toString()).get('quantity_imported').setValue(data.quantity);
            $this.inspectionForm.get('stock').get(index.toString()).get('stock_remained').setValue(data.stock_remained);
            $this.inspectionForm.get('stock').get(index.toString()).get('unit').setValue(data.unit);
            if ($this.getDate($this.date2) >= $this.getDate(data.exp_date)) {
              $this.inspectionForm.get('stock').get(index.toString()).get('validity').setValue('No');
            }
            if ($this.getDate($this.date2) < $this.getDate(data.exp_date)) {
              $this.inspectionForm.get('stock').get(index.toString()).get('validity').setValue('Yes');
            }
          }
        );
      }
    }, 1300);
  }

  getRemained(a, b) {
    if ( a > 0) {
       return (a - b);
    }
  }

  setFirmId(id, index) {
    this.firmId = index;
    this.firmCategory = this.firms[index]['categoryId'];
    this.inspectionForm.get('firmId').setValue(id);
  }

  goBack() {
    this.loc.back();
  }

  get stocks() {
    return this.inspectionForm.get('stock') as FormArray;
  }

  addAlias() {
    this.stocks.push(
      this.fb.group({
        pesticide: ['', Validators.required],
        origin_country: ['', Validators.required],
        batch_number: ['', Validators.required],
        quantity_imported: ['', Validators.required],
        quantity_sold: ['', Validators.required],
        stock_remained: ['', Validators.required],
        validity: ['', Validators.required]
      })
    );
  }
  removeAlis(index) {
    this.stocks.removeAt(index);
  }

  errorMessage() {
    return 'Field is required';
  }

  onChooseOffence() {
    if (this.inspectionForm.get('offence').get('offence').value === 'YES') {
      this.inspectionForm.get('offence').get('offence_details').setValidators(Validators.required);
      this.inspectionForm.get('offence').get('action_taken').setValidators(Validators.required);
    }
  }

  onSubmit() {
    console.log(this.inspectionForm.value);
    this.inspectionService.addInspection(this.inspectionForm.value).subscribe(
      (data) => {
        this.router.navigate(['/app/specific-inspection', {id: data}]);
        this.onOpenSnackBar('Inspection successfully added');
      }
    );
  }

  // onUpdate() {
  //   const data = {
  //     data: this.pesticideForm.value,
  //     pesticideId: this.pesticide.id
  //   };

  //   this.pesticideService.updatePesticide(data).subscribe(
  //     (data) => {
  //       this.onOpenSnackBar('Pesticide successfully updated');
  //     }
  //   );
  // }

  onOpenSnackBar(message: string) {
    this.snackBar.open(message, '', { duration: 3000});
  }

}
export interface Summary {
   firms;
   pesticides;
   countries;
}

export interface Data {
  trade_name;
  common_name;
  reg_number;
  reg_type;
  reg_status;
  valid_from;
  valid_to;
  registrant;
  usage;
  unit;
}

export interface Batch {
  quantity;
  exp_date;
  trade_name;
  stock_remained;
  unit;
  origin_country; }

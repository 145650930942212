import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModuleService } from '../../business/module.service';
import { StockService } from '../stock.service';

@Component({
  selector: 'app-stock-details',
  templateUrl: './stock-details.component.html',
  styleUrls: ['./stock-details.component.css']
})
export class StockDetailsComponent implements OnInit {
  id;
  pesticide;
  isAuth;
  constructor(
    private active: ActivatedRoute,
    private router: Router,
    public moduleService: ModuleService,
    private stockService: StockService
  ) {
    this.active.params.subscribe( params => this.id = params['id']);
    this.isAuth = moduleService.isAuth();
  }

  ngOnInit() {
    this.stockService.getStock(this.id).subscribe(
      (data: Data) => {
        console.log(data);
        this.pesticide = data;
      }
    );
  }

  onEdit(id) {
    this.router.navigate(['app/add-pesticide', {id}]);
  }

}

export interface Data {
 adviser;
 business;
}

import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {FormControl} from '@angular/forms';
import { Router } from '@angular/router';
import { InspectionService } from '../inspection.service';
import {MatPaginator} from '@angular/material/paginator';
import { ModuleService } from '../../business/module.service';
@Component({
  selector: 'app-inspections',
  templateUrl: './inspections.component.html',
  styleUrls: ['./inspections.component.css']
})
export class InspectionsComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  displayedColumns: string[] = ['position', 'business', 'category', 'date', 'location', 'offence', 'action_taken', 'actions'];
  dataSource;
  clear = false;
  inspections: Inspection[];
  bulkInspections: Inspection[];
  date = new FormControl('');
  location = new FormControl('');
  to = new FormControl('');
  name = new FormControl('');
  action = new FormControl('');
  offence = new FormControl('');
  offences = [
    {value: 'YES', viewValue: 'YES'},
    {value: 'NO', viewValue: 'NO'},
  ];
  categories = [
    'sellers',
    'fumigators',
    're-packers',
    'formulators',
    'importers'
  ];
  locations = [
    'DAR ES SALAAM', 'KIGOMA'
  ];
  actions = [
    'Licence revocked',
    'Business closed',
    'Given warning',
    'Taken to court'
  ];

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    private route: Router,
    private inspectionService: InspectionService,
    public moduleService: ModuleService
    ) { }

  ngOnInit() {
    this.inspectionService.getInspections().subscribe(
      (data: Inspection[]) => {
       this.inspections = data;
       this.bulkInspections = data;
       this.dataSource = new MatTableDataSource(this.inspections);
       this.dataSource.sort = this.sort;
       this.dataSource.paginator = this.paginator;
      }
    );
  }

  clearFilter() {
    this.inspections = this.bulkInspections;
    this.dataSource = new MatTableDataSource(this.inspections);
    this.clear = false;
    this.date.setValue('');
    this.name.setValue('');
    this.action.setValue('');
    this.offence.setValue('');
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getDate(date) {
    return new Date(date);
  }
  applyGroupFilter() {
    let value: Inspection[] = [];
    if (this.name.value !== '' && this.date.value !== '' && this.action.value !== '' && this.offence.value !== ''
        && this.to.value !== '' && this.location.value !== ''  ) {
      value = this.bulkInspections.filter(i => i.categoryId === this.name.value && this.getDate(i.date) >= this.getDate(this.date.value)
      && i.regionId === this.location.value
      && this.getDate(i.date) <= this.getDate(this.to.value) && i.action_taken === this.action.value && i.offence === this.offence.value);
    } else {
      if (this.date.value !== '' && this.action.value !== '' && this.offence.value !== ''
      && this.to.value !== '' && this.location.value !== ''  ) {
    value = this.bulkInspections.filter(i => this.getDate(i.date) >= this.getDate(this.date.value)
    && this.getDate(i.date) <= this.getDate(this.to.value) && i.regionId === this.location.value
     && i.action_taken === this.action.value && i.offence === this.offence.value);
       } else {
        if (this.name.value !== '' && this.date.value !== '' && this.action.value !== ''
        && this.to.value !== '' && this.location.value !== ''  ) {
      value = this.bulkInspections.filter(i => i.categoryId === this.name.value && this.getDate(i.date) >= this.getDate(this.date.value)
      && i.regionId === this.location.value
      && this.getDate(i.date) <= this.getDate(this.to.value) && i.action_taken === this.action.value);
    } else {
      if (this.name.value !== '' && this.date.value !== ''  && this.offence.value !== ''
      && this.to.value !== '' && this.location.value !== ''  ) {
    value = this.bulkInspections.filter(i => i.categoryId === this.name.value && this.getDate(i.date) >= this.getDate(this.date.value)
    && i.regionId === this.location.value
    && this.getDate(i.date) <= this.getDate(this.to.value) && i.offence === this.offence.value);
      }
      if (this.name.value !== '' && this.date.value !== '' && this.action.value !== '' && this.offence.value !== ''
      && this.to.value !== '') {
    value = this.bulkInspections.filter(i => i.categoryId === this.name.value && this.getDate(i.date) >= this.getDate(this.date.value)
    && this.getDate(i.date) <= this.getDate(this.to.value) && i.action_taken === this.action.value && i.offence === this.offence.value);
  } else {
    if (this.name.value !== '' && this.action.value !== '' && this.offence.value !== ''
        && this.to.value !== '' && this.location.value !== ''  ) {
      value = this.bulkInspections.filter(i => i.categoryId === this.name.value
      && i.regionId === this.location.value
      && this.getDate(i.date) <= this.getDate(this.to.value) && i.action_taken === this.action.value && i.offence === this.offence.value);
      } else {
        if (this.name.value !== '' && this.date.value !== '' && this.action.value !== '' && this.offence.value !== ''
        && this.location.value !== ''  ) {
      value = this.bulkInspections.filter(i => i.categoryId === this.name.value && this.getDate(i.date) >= this.getDate(this.date.value)
      && i.regionId === this.location.value
      && i.action_taken === this.action.value && i.offence === this.offence.value);
        } else {
          if (this.name.value !== '' && this.action.value !== '' && this.offence.value !== ''
        && this.location.value !== ''  ) {
      value = this.bulkInspections.filter(i => i.categoryId === this.name.value
      && i.regionId === this.location.value && i.action_taken === this.action.value && i.offence === this.offence.value);
      } else {
        if (this.name.value !== '' && this.date.value !== '' && this.action.value !== '' && this.offence.value !== ''
        && this.to.value !== '' && this.location.value !== ''  ) {
      value = this.bulkInspections.filter(i => i.categoryId === this.name.value && this.getDate(i.date) >= this.getDate(this.date.value)
      && i.regionId === this.location.value
      && this.getDate(i.date) <= this.getDate(this.to.value) && i.action_taken === this.action.value && i.offence === this.offence.value);
        } else {
          if (this.name.value !== '' && this.date.value !== ''
        && this.to.value !== '' && this.location.value !== ''  ) {
      value = this.bulkInspections.filter(i => i.categoryId === this.name.value && this.getDate(i.date) >= this.getDate(this.date.value)
      && i.regionId === this.location.value
      && this.getDate(i.date) <= this.getDate(this.to.value));} else {
        if (this.name.value !== '' && this.date.value !== '' && this.action.value !== '' && this.offence.value !== ''
        && this.to.value !== '' && this.location.value !== ''  ) {
      value = this.bulkInspections.filter(i => i.categoryId === this.name.value && this.getDate(i.date) >= this.getDate(this.date.value)
      && i.regionId === this.location.value
      && this.getDate(i.date) <= this.getDate(this.to.value) && i.action_taken === this.action.value && i.offence === this.offence.value);}
      else {
        if (this.date.value !== '' && this.action.value !== '' && this.offence.value !== ''
        && this.to.value !== '') {
      value = this.bulkInspections.filter(i => this.getDate(i.date) >= this.getDate(this.date.value)
      && this.getDate(i.date) <= this.getDate(this.to.value) && i.action_taken === this.action.value && i.offence === this.offence.value);
    } else {
      if (this.name.value !== '' && this.date.value !== ''  && this.to.value !== ''  ) {
      value = this.bulkInspections.filter(i => i.categoryId === this.name.value && this.getDate(i.date) >= this.getDate(this.date.value)
      && this.getDate(i.date) <= this.getDate(this.to.value)); } else {
      if (this.date.value !== '' && this.to.value !== '' && this.location.value !== ''  ) {
      value = this.bulkInspections.filter(i => this.getDate(i.date) >= this.getDate(this.date.value)
      && i.regionId === this.location.value
      && this.getDate(i.date) <= this.getDate(this.to.value)); } else {
        if (this.date.value !== '' && this.offence.value !== ''
        && this.to.value !== '') {
      value = this.bulkInspections.filter(i => this.getDate(i.date) >= this.getDate(this.date.value)
      && this.getDate(i.date) <= this.getDate(this.to.value) && i.offence === this.offence.value); } else {
        if (this.date.value !== '' && this.action.value !== ''
        && this.to.value !== '') {
      value = this.bulkInspections.filter(i => this.getDate(i.date) >= this.getDate(this.date.value)
      && this.getDate(i.date) <= this.getDate(this.to.value) && i.action_taken === this.action.value); } else {
        if (this.date.value !== '' && this.to.value !== '') {
      value = this.bulkInspections.filter(i => this.getDate(i.date) >= this.getDate(this.date.value)
      && this.getDate(i.date) <= this.getDate(this.to.value)); } else {
        if (this.name.value !== '' && this.location.value !== ''  ) {
      value = this.bulkInspections.filter(i => i.categoryId === this.name.value
      && i.regionId === this.location.value); } else {
        if (this.action.value !== '' && this.offence.value !== '' ) {
      value = this.bulkInspections.filter(i =>  i.action_taken === this.action.value && i.offence === this.offence.value); } else {
        if (this.name.value !== '') {
          value = this.bulkInspections.filter(i => i.categoryId === this.name.value); } else {
            if (this.location.value !== ''  ) {
              value = this.bulkInspections.filter(i => i.regionId === this.location.value); } else {
                if (this.action.value !== '') {
                  value = this.bulkInspections.filter(i =>  i.action_taken === this.action.value); } else {
                    if (this.offence.value !== '' ) {
                      value = this.bulkInspections.filter(i =>  i.action_taken === this.action.value); } else {
                        if (this.date.value !== '') {
                          value = this.bulkInspections.filter(i => this.getDate(i.date) >= this.getDate(this.date.value)); } else {
                            if (this.to.value !== '') {
                              value = this.bulkInspections.filter(i =>this.getDate(i.date) <= this.getDate(this.to.value)); }
                          }
                      }
                  }
              }
          }

      }

      }
      }

      }

      }

      }

      }

      }

      }

      }

        }
      }
        }
      }
    }
    }
       }
    }

    // tslint:disable-next-line: prefer-for-of
    // for (let i = 0; i < this.bulkInspections.length; i++) {

    //   if (this.name.value !== '' && this.date.value !== '' && this.action.value !== '' && this.offence.value !== '' ) {
    //    if (this.bulkInspections[i].categoryId === this.name.value && this.bulkInspections[i].date === this.date.value &&
    //        this.bulkInspections[i].action_taken === this.action.value && this.bulkInspections[i].offence === this.offence.value) {
    //      value.push(this.bulkInspections[i]); }
    //     } else {
    //       if (this.name.value !== '' && this.date.value !== '' && this.action.value !== '' ) {
    //         if (this.bulkInspections[i].categoryId === this.name.value && this.bulkInspections[i].date === this.date.value &&
    //           this.bulkInspections[i].action_taken === this.action.value ) {
    //             value.push(this.bulkInspections[i]);  }
    //         } else {
    //           if (this.name.value !== '' && this.date.value !== '') {
    //             if (this.bulkInspections[i].categoryId === this.name.value && this.bulkInspections[i].date === this.date.value) {
    //               value.push(this.bulkInspections[i]);  }
    //             } else {
    //               if (this.offence.value !== '' && this.date.value !== '') {
    //                 if (this.bulkInspections[i].offence === this.offence.value && this.bulkInspections[i].date === this.date.value) {
    //                   value.push(this.bulkInspections[i]);  }
    //                 } else {
    //                 if (this.name.value !== '' ) {
    //                   if (this.bulkInspections[i].categoryId === this.name.value) {
    //                     value.push(this.bulkInspections[i]);  }
    //                   } else {
    //                     if (this.date.value !== '') {
    //                       if (this.bulkInspections[i].date === this.date.value) {
    //                         value.push(this.bulkInspections[i]);  }
    //                       } else {
    //                         if (this.offence.value !== ''  ) {
    //                           if (this.bulkInspections[i].offence === this.offence.value) {
    //                             value.push(this.bulkInspections[i]); }
    //                           } else {
    //                             if (this.action.value !== '') {
    //                               if (this.bulkInspections[i].action_taken === this.action.value ) {
    //                                 value.push(this.bulkInspections[i]);  }}
    //                           }    }
    //                   }
    //               }
    //             }
    //         }
    //     }
    // }
    this.inspections = value;
    this.dataSource = new MatTableDataSource(this.inspections);
    this.clear = true;
  }

  onAddBussiness() {
    this.route.navigate(['app/add-inspection']);
  }
  toDetails(id) {
    this.route.navigate(['app/specific-inspection', {id}]);
  }

  onExport() {
    this.inspectionService.export();
  }

}

export interface Inspection {
   business;
   date;
   offence;
   action_taken;
   firm;
   categoryId;
   regionId;
}


import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { PesticideService } from './../../../modules/pesticides-module/pesticide.service';
import { ModuleService } from 'src/app/modules/business/module.service';

export interface DialogData {
  id;
  type;
}

@Component({
  selector: 'app-delete-data',
  templateUrl: './delete-data.component.html',
  styleUrls: ['./delete-data.component.css']
})
export class DeleteDataComponent implements OnInit {
  isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<DeleteDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private pesticidesService: PesticideService,
    private snackBar: MatSnackBar,
    private moduleService: ModuleService
  ) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  onSubmit() {
    this.isLoading = true;
    if (this.data.type === 'pesticide'){
      this.pesticidesService.deletePesticide(this.data.id).subscribe(
        (resp: string) => {
          this.snackBar.open('Pesticide ' + resp,'', {
            duration: 5000
          });
          this.onNoClick();
        }
      );
    }
    if (this.data.type === 'business'){
      this.moduleService.deleteBusiness(this.data.id).subscribe(
        (resp: string) => {
          this.snackBar.open('Business ' + resp,'', {
            duration: 5000
          });
          this.onNoClick();
        }
      );
    }
  }
}
